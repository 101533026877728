
// * react icons
import { FaPython, FaJs, FaReact, FaHtml5, FaCss3, FaSass, FaNodeJs, FaGithub, FaGitAlt, FaYarn, FaNpm } from 'react-icons/fa'
import { SiMongodb, SiPostgresql, SiHeroku, SiVisualstudiocode, SiInsomnia, SiJquery, SiExpress, SiGooglechrome, SiNetlify } from 'react-icons/si'
import { DiDjango } from 'react-icons/di'



const Skills = () => {

  return (
    <>
      <h2 className='section-header' data-aos='fade-right' data-aos-delay='300' data-aos-duration='2000'>Sk<span id='skill-title-span'>i</span>lls</h2>
      {/* <div className='underline' data-aos='fade-left' data-aos-delay='300' data-aos-duration='2000'></div> */}
      <div className='skills-container' data-aos='fade-in' data-aos-delay='800' data-aos-duration='3000'>
        <ul>
          <li>
            <FaPython size={50} /> Python
          </li>
          <li>
            <FaJs size={50} /> JavaScript
          </li>
          <li>
            <FaReact size={50} /> React
          </li>
          <li>
            <FaHtml5 size={50} /> HTML5
          </li>
          <li>
            <FaCss3 size={50} /> CSS3
          </li>
          <li>
            <FaSass size={50} /> Sass
          </li>
          <li>
            <FaNodeJs size={50} /> Node.js
          </li>
          <li>
            <SiExpress size={50} /> Express
          </li>
          <li>
            <SiJquery size={50} /> Jquery
          </li>
          <li>
            <SiMongodb size={50} /> MongoDB
          </li>
          <li>
            <DiDjango size={50} /> Django
          </li>
          <li>
            <SiPostgresql size={50} /> PostgreSQL
          </li>
          <li>
            <SiGooglechrome size={50} /> Chrome Dev Tools
          </li>
          <li>
            <FaGithub size={50} /> GitHub
          </li>
          <li>
            <FaGitAlt size={50} /> Git
          </li>
          <li>
            <FaYarn size={50} /> Yarn
          </li>
          <li>
            <FaNpm size={50} /> Npm
          </li>
          <li>
            <SiHeroku size={50} /> Heroku
          </li>
          <li>
            <SiNetlify size={50} /> Netlify
          </li>
          <li>
            <SiVisualstudiocode size={50} /> VS Code
          </li>
          <li>
            <SiInsomnia size={50} /> Insomnia
          </li>
        </ul>
      </div>
    </>
  )
}

export default Skills