const Experience = () => {
  return (
    <>
      <h2 className='section-header' data-aos='fade-right' data-aos-delay='300' data-aos-duration='2000'>Exp<span id='xp-title-span'>e</span>rience</h2>
      <div className='xp-container' data-aos='fade-in' data-aos-delay='800' data-aos-duration='3000'>
        <div className='xp1'>
          <h3>General Assembly</h3>
          <h5>Software Engineering Immersive</h5>
          <span>September 2022 - Jan 2023</span>
          <p> - Learned and utilized the fundamentals of web development and design for Full-Stack applications.</p>
          <p> - Built applications in solo, team and pair based projects in weeklong projects and 48hr hackathons.</p>
          <p> - Key skills: JavaScript, Python, HTML5, MongoDB and SQL || MERN applications and RESTful APIs.</p>
        </div>
        <div className='xp2'>
          <h3>Strategy Insights</h3>
          <h5>Business Development Executive</h5>
          <span>February 2022 - July 2022</span>
          <p> - Business Development Executive in Strategy Insights' IT Sector division.</p>
          <p> - Acquired and managed large and medium enterprise client accounts in the IT sector across EMEA markets.</p>
          <p> - Advised large-enterprise clients to successfully streamline acquisition of high-value contracts within target industries.</p>
          <p> - Independently developed pipeline for client pitching, acquisition & and delivery of services.</p>
        </div>
        <div className='xp3'>
          <h3>Knightsbridge Recruitment (Cytora)</h3>
          <h5>Data Entry Operator</h5>
          <span>Oct 2021 - Dec 2021</span>
          <p> - Optimized ML software performance using data input and analysis to drive accuracy improvements at a top digital risk management company</p>
          <p> - Delivered on daily targets in a team and solo working environment & chosen to extend contract due to exemplary work </p>
        </div>
      </div>
    </>
  )
}

export default Experience