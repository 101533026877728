
// * react icons
import { FaLink, FaGithubSquare } from 'react-icons/fa'


// * images
import BetaImage from '../../images/beta-image.png'
import LaybyImage from '../../images/layby-image.png'
import FixingGoodImage from '../../images/fixing-good-image.png'
import MinesweeperImage from '../../images/minesweeper-image.png'


const Projects = () => {
  return (
    <>
      <h2 className='section-header' data-aos='fade-right' data-aos-delay='300' data-aos-duration='2000'>Pr<span id='project-title-span'>o</span>jects</h2>
      <div className='projects-container' data-aos='fade-in' data-aos-delay='800' data-aos-duration='3000'>
        <div className='project4'>
          <a className='project-heading' href='https://beta.herokuapp.com/' target='_blank' rel='noreferrer'>Beta</a>
          <img width='500px' height='300px' src={BetaImage} alt='Beta site page'></img>
          <p>
            Beta is a community focussed application designed with the developer in mind. Share your past and current projects to receive feedback from the community on everything from UX to accessibility.
          </p>
          <p>Key responsibilities: As it was a solo project I had full responsibility for all aspects of delivery and developed the Full-Stack.</p>
          <p>Key technologies: React.js, Python, Django, PostgreSQL, Heroku</p>
          <p>Timeframe: 9 days || Solo Project</p>
          <div className='button-container'>
            <a href='https://beta.herokuapp.com/' target='_blank' rel='noreferrer' className='project-button'><FaLink /> Project link</a>
            <a href='https://github.com/ajx-mijo/beta' target='_blank' rel='noreferrer' className='project-button'><FaGithubSquare /> Project Repo</a>
          </div>
        </div>
        <div className='project3'>
          <a className='project-heading' href='https://layby-van.herokuapp.com/' target='_blank' rel='noreferrer'>Layby</a>
          <img width='500px' height='300px' src={LaybyImage} alt='Layby site page'></img>
          <p>
            Layby is an application designed to allow fellow travellers and lovers-of-the-vanlife to share their favourite, special and maybe not-so-special spots from around Europe. With added features such as GeoLocation, local amenities information and even the nearest petrol station to never get lost again on your adventures!
          </p>
          <p>Key responsibilities: individual and index location pages, Front-End & Back-End authentication, 3rd Party APIs, Back-End controllers, database seed and responsive design.</p>
          <p>Key technologies: React.js, Node.js, Mongoose, MongoDB, Heroku</p>
          <p>Timeframe: 9 days || Group Project (3) </p>
          <div className='button-container'>
            <a href='https://layby-van.herokuapp.com/' target='_blank' rel='noreferrer' className='project-button'><FaLink /> Project Link</a>
            <a href='https://github.com/ajx-mijo/layby' target='_blank' rel='noreferrer' className='project-button'><FaGithubSquare /> Project Repo</a>
          </div>
        </div>
        <div className='project2'>
          <a className='project-heading' href='https://fixing-good.netlify.app/' target='_blank' rel='noreferrer'>Fixing Good</a>
          <img width='500px' height='300px' src={FixingGoodImage} alt='Fixing Good site page'></img>
          <p>Is your inner Walter White yearning to break free? Fixing Good is an application that allows you to take a tour down memory lane and check out the Bios of all your favourite characters from the world of Heisenberg.</p>
          <p>Key responsibilities: Individual character page, responsive design over all screen sizes, search bar functionality on index page.</p>
          <p>Key technologies: React.js, RESTful APIs, Netlify</p>
          <p>Timeframe: 48hrs || Pair Hackathon</p>
          <div className='button-container'>
            <a href='https://fixing-good.netlify.app/' target='_blank' rel='noreferrer' className='project-button'><FaLink /> Project Link</a>
            <a href='https://github.com/ajx-mijo/breaking-bad' target='_blank' rel='noreferrer' className='project-button'><FaGithubSquare /> Project Repo</a>
          </div>
        </div>
        <div className='project1'>
          <a className='project-heading' href='https://ajx-mijo.github.io/project-minesweeper/' target='_blank' rel='noreferrer'>Minesweeper</a>
          <img width='500px' height='300px' src={MinesweeperImage} alt='Minesweeper site page'></img>
          <p>
            Minesweeper - the classic game of logic and peril! My first programming project built using Vanilla JS, the DOM and an HTML/CSS webpage.
          </p>
          <p>Key responsibilities: full responsibility for logical JavaScript functions, the DOM & CSS design.</p>
          <p>Key technologies: JavaScript, HTML5, CSS, GitHub</p>
          <p>Timeframe: 7 days || Solo Project</p>
          <div className='button-container'>
            <a href='https://ajx-mijo.github.io/project-minesweeper/' target='_blank' rel='noreferrer' className='project-button'><FaLink /> Project Link</a>
            <a href='https://github.com/ajx-mijo/project-minesweeper' target='_blank' rel='noreferrer' className='project-button'><FaGithubSquare /> Project Repo</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Projects