
// * react icons
import { FaGithubSquare, FaLinkedin } from 'react-icons/fa'
import { SiMinutemailer } from 'react-icons/si'

const Contact = () => {
  return (
    <>
      <h2 className='section-header' data-aos='fade-right' data-aos-delay='300' data-aos-duration='2000'>C<span id='contact-title-span'>o</span>ntact</h2>
      <div className='contact-container' data-aos='fade-in' data-aos-delay='800' data-aos-duration='3000'>
        <p>
          You can reach me via email or on social media at:
        </p>
        <ul>
          <li>
            <SiMinutemailer size={20} />
            <a href='mailto:archibald.rh@gmail.com' target='_blank' rel='noreferrer'>archibald.rh@gmail.com</a>
          </li>
          <li>
            <FaLinkedin size={20} />
            <a href='https://www.linkedin.com/in/archie-rowan-hamilton-3a38b61b8/' target='_blank' rel='noreferrer'>linkedin.com/in/archie-rowan-hamilton</a>
          </li>
          <li>
            <FaGithubSquare size={20} />
            <a href='https://github.com/ajx-mijo' target='_blank' rel='noreferrer'>github.com/ajx-mijo</a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Contact