
// * images
import ProfileImage from '../../images/portfolio-image-modified.png'

const AboutMe = () => {
  return (
    <>
      <h2 className='section-header' data-aos='fade-right' data-aos-delay='300' data-aos-duration='2000'>Ab<span id='abt-title-span'>o</span>ut Me</h2>
      <div className='aboutMe-container' data-aos='fade-in' data-aos-delay='800' data-aos-duration='3000'>
        <img width='200px' src={ProfileImage} alt='Profile Headshot' className='profile-image'></img>
        <p>
          Hi, I'm Archie, a <span>Full-Stack Web Developer</span> based in London.
        </p>
        <p>Languages have been the name of the game for me since I was very young. Whether I was speaking Spanish in Salamanca or trying to help (read hinder) my friend make changes to their art website, I have always been drawn to problem solving, logical thinking and making things!</p>
        <p>
          Prior to entering the world of Software Engineering, I worked in IT Management Consulting helping software companies win contracts with large enterprise. I thoroughly enjoyed my work with clients in the tech space but found the sales and advisory process wasn't scratching my itch. So I doubled down on my self-teaching of basic Web Development, applied to General Assembly and accelerated my development. Passionate about confronting challenges, devising solutions and generally making the world a more functional place - <span className='aboutMe-span'>one line of code at a time</span>.
        </p>
        <p> When I'm not sat at my computer you will probably find me up a mountain (sunshine or snow!), poring over a cookbook (Lily Ramirez-Foran's TACOS is my current challenge) or wildly supporting the Irish Rugby team.</p>
      </div>
    </>
  )
}

export default AboutMe