import { HashRouter } from "react-router-dom";

import { HashLink as Link } from 'react-router-hash-link'


import Typewriter from 'typewriter-effect'

// * react icons
import { HiChevronDoubleDown } from 'react-icons/hi'


// * components
import Skills from './components/common/Skills'
import Projects from './components/common/Projects'
import Experience from './components/common/Experience'
import AboutMe from './components/common/AboutMe'
import Contact from './components/common/Contact'

// * aos
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
  once: true
})


const App = () => {

  return (
    <>
      <HashRouter>
        <nav className='navbar'>
          <Link to="/#skills-section">Skills</Link>
          <Link to="/#projects-section">Projects</Link>
          <Link to="/#xp-section">Experience</Link>
          <Link to="/#aboutMe-section">About</Link>
          <Link to="/#contact-section">Contact</Link>
        </nav>
        <main>
          <section className='title' id='title-section'>
            <div className='title-container'>
              <h1 className='title-header'>print<span className='parentheses'>("</span></h1>
              <div className='typewriter-container'>
                <Typewriter
                  className='typewriter'
                  options={{
                    strings: ['Hello World!', 'My name is Archie', 'Full-Stack Web Developer'],
                    autoStart: true,
                    pauseFor: 1000,
                    skipAddStyles: true,
                    loop: true,
                  }}
                />
              </div>
              <h1 className='parentheses'>");</h1>
            </div>
            <div className='title-scroller'>
              <HiChevronDoubleDown size={30} />
              <span>Scroll down</span>
            </div>
          </section>
          <section className='skills' id='skills-section'>
            <Skills />
          </section>
          <section className='projects' id='projects-section'>
            <Projects />
          </section>
          <section className='xp' id='xp-section'>
            <Experience />
          </section>
          <section className='aboutMe' id='aboutMe-section'>
            <AboutMe />
          </section>
          <section className='contact' id="contact-section">
            <Contact />
          </section>
        </main>
      </HashRouter>
    </>

  )
}

export default App
